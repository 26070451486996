<template>
  <div :class="$style[classes]">
		<div :class="$style['form']">
		<li>当前绑定手机号：{{mobile}}</li>
			<validate-form tag="table" ref="form" :class="$style['pt-phone-setting-form']">
				<validate-form  ref="phone">
				<wt-form-item label="新手机号：" rules="required|phone"  name="新手机号" :widthMax="width" ref="mobile" tag="div" lineHeight="30" :showError="false">
					<wt-input v-model="formValue.newMobile" placeholder="请输入新手机号"></wt-input>
				</wt-form-item>
				</validate-form>
				<wt-form-item label="验证码：" name="验证码" ref="formItem" tag="div"  rules="required" :widthMax="width" lineHeight="30" :showError="false">
					<wt-input v-model="formValue.code" placeholder="请输入新验证码">
            <wt-count-down-box
              slot="rt"
              ref="countDownBox"
              :class="$style.getValidCode"
              @on-start="startGetCode"
              >获取验证码</wt-count-down-box
            >
					</wt-input>
				</wt-form-item>
			</validate-form>
			</div>
			<div :class="$style['button-div']">
				<wt-button @click="edit" primary :class="$style['next-button']">确认修改</wt-button>
			</div>
	</div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-phone-setting'

import formMixins from 'mixins/form'
export default {
  name: 'pt-phone-setting',
  mixins: [formMixins],
  data (){
    return {
      formValue:{},
      width:80
    }
  },
  computed:{
    countDownBox () {
      return this.$refs.countDownBox;
    }
  },
  methods:{
    edit (){
      this.validate('form').then(res => {
        this.$emit('editUserMobile', this.formValue)
      })
    },
    startGetCode (next){
      this.$nextTick((_) => {
        this.validate(null, 'phone').then(res => {
          next();
          this.$emit("getCode", this.formValue.newMobile, this.$refs.countDownBox);
        }).catch(err => {
          this.$nextTick((_) => {
            this.$refs.countDownBox.reset();
          });
        })
      });
    }
  },
  computed: {
    classes () {
      return `${prefixCls}`
    },
    userMessage (){
      return this.$store.state.currentUserMessage || {}
    },
    mobile (){
      return this.userMessage.mobile
    }
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-phone-setting{
	li{
		padding:10px @page-padding;
		text-align:left;
		color:@font-of-grey-opacity
	}
}
.pt-phone-setting-form{
	width:100%;
	:global{
			.wt-input,.wt-select{
				padding: 5px 15px;
			}
			.cube-input{
				background-color:rgba(255, 255, 255, 0)
			}
		.wt-form-list-item{
			padding:10px @page-padding;
			display: flex;
			width: 100%;
			border-top:1Px solid @font-of-grey;
			box-sizing: border-box;
			margin-bottom:0;
      .wt-count-down-box {
        color: @main-color!important;
				// font-size: 13px;
				border:none;
				padding-right: 0;
				min-width: 5em;
      }
			.label-text{
				justify-content:center
			}
		}
	}
}
.form{
	background-color:#fff;
}
.button-div{
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
	.next-button{
		width: 100%
	}
}
</style>
