// 表单验证器
export default {
  methods: {
    async validate(key = 'form', keySome) {
      const isValid = keySome ? await this.$refs[keySome].validate() : await this.$refs.form.validate()
      if (isValid) {
        return Promise.resolve(this[key])
      } else {
        let form = keySome?this.$refs[keySome]:this.$refs.form
        let errors = Object.values(form.errors)
        let firstErrorIndex = errors.findIndex(val => val.length >= 1)
        let error = errors[firstErrorIndex] ? errors[firstErrorIndex][0] : '验证异常'
        this.$toast.error(error)
        return Promise.reject(error)
      }
    }
  }
}