<template>
  <pt-phone-setting class="pt-phone-setting__withData" @getCode="getCode" @editUserMobile="editUserMobile" ref="form"></pt-phone-setting>
</template>

<script>
import PtPhoneSetting from './index'
import {hasPermissionsOfTrust} from "formats/permissions.js"
export default {
  name: 'pt-phone-setting__withData',
  components: {
    'pt-phone-setting': PtPhoneSetting
  },
  methods:{
    editUserMobile (formValue){
      let data = {
        ...formValue
      }
      this.$store.dispatch('editUserMobile', data).then(res => {
        this.$toast.success('修改手机号成功')
				let path = hasPermissionsOfTrust() ? '/Home' : '/task-order'
				this.getUserInformation()
        setTimeout(_ => {
          this.$router.push(path)
        }, 3000)
      }).catch(err => {
        this.$toast.error(err || '修改手机号失败')
      })
		},
		getUserInformation () {
      this.$store
        .dispatch("getUserInformation")
        .then((res) => {})
        .catch((err) => {
          this.$toast.success(err || '获取用户信息失败')
          // setTimeout(_ => {
          //   this.getUserInformation();
          // }, 1000)
        });
    },
    getCode (mobile, getCodeVM) {
      let data = {
        mobilePhone: mobile,
        businessKey: "change-mobile"
      };
      this.$store
        .dispatch("getCode", data)
        .then((res) => {
          if (res.data && res.data.message) {
            this.$toast.info(res.data.message);
          }
          // 自动聚焦到验证码输入框
          this.$refs.form.autofocusAuthorizationCode = true;
        })
        .catch((errorMessage) => {
          console.log("errorMessage", errorMessage);
          this.$toast.error(errorMessage);
          getCodeVM.reset();
        });
    }
  }
}
</script>
