<template>
  <pt-phone-setting class="pt-phone-setting__page"></pt-phone-setting>
</template>

<script>
import PtPhoneSetting from 'cps/pt-user-setting/pt-phone-setting/withData'
export default {
  name: 'pt-phone-setting__view',
  components: {
    'pt-phone-setting': PtPhoneSetting
  }
}
</script>
